.enhanced_signin_page {
  .checkout__content {
    #sign-in-panel {
      .checkout__return-user,
      .checkout__new-account {
        .form-item {
          .label {
            display: none;
          }
        }
      }
    }
  }
}
