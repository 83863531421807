.site-container {
  .checkout {
    .checkout__sidebar {
      .product__desc {
        .add-to-cart {
          .btn {
            // Allow for longer copy on the "add to cart" button
            font-size: 11px;
            @include swap_direction(padding, 0px 12px);
          }
        }
      }
    }
  }
}

.checkout__content {
  .shipping-panel {
    .select-address.form-container {
      #google_locationField {
        margin-bottom: 15px;
      }
    }
    .corporate-invoice {
      fieldset.fs.corporate_invoice {
        .corporate-vat-lookup {
          // The margin is already provided by the containing table
          margin-bottom: 0px;
        }
      }
    }
  }
}

.confirm {
  .checkout-confirmation-page {
    .responsive-container {
      padding: 0 20px 0 20px;
    }
  }
}

.checkout__sidebar {
  #past-purchases-panel {
    display: block;
  }
}

.checkout {
  &.active-panel-shipping {
    .checkout__sidebar {
      #shopping-bag-panel,
      #viewcart-shopping-bag-panel {
        display: none;
      }
    }
  }
}

.loyalty-samples-panel {
  .checkout-header {
    text-align: center;
  }
  .loyalty-products {
    .product-list {
      .product {
        background: #f0f0f0;
        padding: 1em;
        .sample-select-button {
          width: 100%;
        }
      }
    }
  }
}
