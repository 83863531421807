body {
  &.section-maclover-terms-conditions {
    #intro {
      margin-top: 1.5em;
      font-weight: bold;
    }
    ol {
      counter-reset: item;
      margin-#{$ldirection}: 1em;
    }
    li {
      display: block;
    }
    li:before {
      content: counters(item, '.') '.';
      counter-increment: item;
      position: absolute;
    }
    li.level1:before {
      font-size: 2.3rem;
    }
    li > h4 {
      display: inline-block;
      font-weight: bold;
      color: $color--black;
      margin-#{$ldirection}: 1em;
    }
    li span {
      margin-#{$ldirection}: 2.4em;
      display: inline-block;
    }
    ol > li {
      margin-bottom: 2em;
      margin-#{$ldirection}: 0em;
    }
    .level2,
    .level3 {
      margin-bottom: 1em;
    }
  }
  #loyalty__content {
    #loyalty__page__index {
      #loyalty__panel {
        &__points,
        &__benefits,
        &__redeem,
        &__next-tier {
          padding: 1em;
        }
        &__points {
          margin-bottom: 0px;
          .mac-select__account-panel__progress {
            padding: 0px;
            .mac-select__account-panel__content-spend {
              white-space: normal;
            }
            .mac-select {
              &__progress {
                &-level {
                  position: absolute;
                  top: 10px;
                  #{$ldirection}: 12px;
                  color: $color--white;
                  text-align: #{$ldirection};
                }
              }
            }
          }
        }
        &__benefits {
          .loyalty__panel__benefits__description ul {
            margin-#{$ldirection}: 1em;
          }
        }
      }
      #loyalty__panel__points {
        .mac-select__progress-level {
          .mac-select__progress-level__title {
            font-family: $roboto-condensed-bold-font;
          }
          .mac-select__progress-level__tier {
            font-family: $roboto-condensed-regular-font;
          }
        }
      }
      .loyalty__panel__next-tier {
        padding: 1em;
      }
      .loyalty__page__index-2col {
        margin-bottom: 0px;
        .loyalty__panel__benefits__title {
          .mac-select__color-tier0 {
            color: $black;
          }
        }
      }
    }
  }
  #loyalty__page__index-bottom {
    .mac-select {
      &__swatch {
        &-tier1 {
          background-color: $tier0color1gr;
        }
        &-tier2 {
          background-color: $tier0color1gr;
        }
        &-tier3 {
          background-color: $tier0color1gr;
        }
        &-tier4 {
          background-color: $tier3color1gr;
        }
      }
    }
  }
  .mac-select {
    &__color {
      &-tier0 {
        color: $tier0color1gr;
      }
      &-tier1 {
        color: $tier1color1gr;
      }
      &-tier2 {
        color: $tier2color1gr;
      }
      &-tier3 {
        color: $tier3color1gr;
      }
    }
    &__swatch {
      &-tier0 {
        background-color: $black;
      }
      &-tier1 {
        background-color: $tier1color1gr;
      }
      &-tier2 {
        background-color: $tier2color1gr;
      }
      &-tier3 {
        background-color: $tier3color1gr;
      }
      &-tier0,
      &-tier1,
      &-tier2,
      &-tier3 {
        background-image: none;
      }
    }
    &__marketing-page__lover {
      .mac-select__marketing-page__header {
        display: block;
      }
      .mac-select__marketing-page__benefits__section {
        &-copy {
          &.mac-select__swatch-tier1,
          &.mac-select__swatch-tier2,
          &.mac-select__swatch-tier3,
          &.mac-select__swatch-lovertier4 {
            ul {
              li {
                justify-content: center;
              }
            }
          }
          &.mac-select__swatch-tier4 ul li span {
            background-color: $color--gray-tier3;
          }
          &.mac-select__swatch-tier3 li span {
            background-color: $color--red-tier2;
          }
          &.mac-select__swatch-tier2 li span {
            background-color: $color--pink-tier1;
          }
          &.mac-select__swatch-tier1 ul li span {
            background-color: $color--black;
          }
        }
      }
      .mac-select__marketing-page__benefits {
        &__section {
          &-title {
            h3 {
              font-family: $roboto-condensed-bold-font;
            }
            h1 {
              display: block;
              margin: 0;
            }
          }
          &-all ul li {
            font-family: $roboto-condensed-bold-font;
          }
        }
      }
    }
  }
  &.section-mac-lover {
    font-family: $body-font-family;
    .mac-select {
      &__marketing-page {
        &__signin {
          margin: 0px;
        }
        &__benefits {
          .mac-select {
            &__swatch {
              &-tier1,
              &-tier2,
              &-tier3,
              &-tier4 {
                background: 0 0;
                background-image: none;
              }
            }
          }
        }
        &__questions {
          padding: 0px;
        }
      }
    }
    #promo-message {
      text-align: center;
      margin-bottom: 1em;
    }
    .custom-color-block__text {
      font-size: 2.5rem;
      padding: 12px 0 14px;
      text-align: center;
      margin: 10px 0;
      font-family: $roboto-condensed-bold-font;
      color: $color--white;
      &:nth-of-type(1) {
        background-color: $tier1color1gr;
      }
      &:nth-of-type(2) {
        background-color: $tier2color1gr;
      }
      &:nth-of-type(3) {
        background-color: $tier3color1gr;
      }
    }
    .mac-lover-level {
      div,
      section {
        overflow: visible;
      }
      .expando-block {
        border-top: unset;
      }
      .grid--mpp {
        padding: 0px;
      }
      .carousel {
        max-height: 420px;
        @include breakpoint($bp--xsmall-up) {
          max-height: 455px;
          min-height: 445px;
        }
        .slick-track {
          .slick-slide {
            min-height: 375px;
            .mpp-complementary-products-v1 {
              padding: 0px;
            }
            .product {
              &--teaser {
                border-top: unset;
                padding: 0px;
              }
              &__header {
                float: unset;
                width: 100%;
              }
              &_header_name {
                width: 100%;
              }
              &__name {
                text-align: center;
              }
              &__description-short {
                text-align: center;
              }
              &__price {
                display: none;
              }
              &--teaser .product__faves--teaser.product__faves--teaser_mobile {
                display: none;
              }
              &__images {
                float: unset;
                width: 100%;
                &-inner {
                  width: 70%;
                  margin: 0 15%;
                }
              }
              &__add-to-bag {
                display: none;
              }
            }
          }
        }
        &-controls {
          bottom: 150px;
          .slick-prev,
          .slick-next {
            height: 50px;
            width: 70px;
            &:before {
              font-size: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }
    /* Styles for collapsed lists */
    .basic-nodeblock-reference-v1.loyalty-tier {
      .expando-block {
        margin: 0px;
        padding: 0px;
        .mpp-custom {
          &__header {
            padding-top: 1.3em;
            padding-bottom: 1.3em;
          }
          &__title {
            margin-bottom: 15px;
          }
          &__content {
            .product__rating,
            .product__price,
            .product__faves--teaser {
              display: none;
            }
          }
        }
        .mpp-complementary-products {
          &__item {
            .product {
              &__faves--teaser {
                display: none;
              }
            }
          }
        }
        &--expanded {
          .expando-block__title {
            margin-bottom: 0px;
          }
        }
      }
      &.tier-1 {
        .mpp-custom__header {
          background-color: $tier1color1gr;
        }
      }
      &.tier-2 {
        .mpp-custom__header {
          background-color: $tier2color1gr;
        }
      }
      &.tier-3 {
        .mpp-custom__header {
          background-color: $tier3color1gr;
        }
      }
    }
    /* Styles for tabs */
    .main-title {
      font-size: 0.7em;
    }
    #terms-questions {
      margin-top: 1.5em;
      text-align: center;
    }
    .product__footer {
      text-align: center;
      .product_content_fav_mobile {
        height: 23px;
        margin-top: 10px;
        .product__link-to-default-spp {
          border: 2px solid $color--black;
          padding: 4px;
        }
      }
    }
    .mac-lover-level--450 {
      min-height: 440px;
      @include breakpoint($bp--xsmall-up) {
        min-height: 465px;
      }
    }
  }
  .site-container div {
    .mac-select__progress {
      overflow: unset !important;
    }
  }
  &.page-product {
    &-loyaltya,
    &-loyaltyb,
    &-loyaltyc {
      .product--full .product__add-to-faves {
        display: none;
      }
    }
  }
  .samples-panel__content {
    .loyalty-products {
      header {
        text-align: center;
        h3 {
          font-family: $roboto-condensed-bold-font;
          font-size: 2.5rem;
          color: $color--white;
          padding: 10px 0px;
        }
      }
      &:nth-of-type(1) {
        header h3 {
          background-color: $tier1color1gr;
        }
      }
      &:nth-of-type(2) {
        header h3 {
          background-color: $tier2color1gr;
        }
      }
      &:nth-of-type(3) {
        header h3 {
          background-color: $tier3color1gr;
        }
      }
    }
  }
}
