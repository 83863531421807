/* Side Panel preorder */
.checkout__sidebar {
  .preorder-panel {
    &.side-panel {
      .cart-item__pretext {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        width: 65%;
        float: #{$rdirection};
        clear: #{$rdirection};
      }
      .total {
        clear: both;
      }
    }
  }
}
/* Order History preorder */
.preorder-history {
  &__header {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__subheader {
    @include swap_direction(margin, 5px 0 10px 0);
    font-size: 14px;
    text-align: center;
  }
}

.preorder-history-container {
  .address-book-page {
    &__content {
      padding: 0;
      .section-head {
        text-align: #{$ldirection};
      }
    }
  }
  .payment-info {
    &__preorder {
      padding: 1em 0;
    }
  }
}

.checkout__content,
.checkout__sidebar {
  .preorder-only {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
    border-bottom: none;
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        .preorder-noshop {
          &.btn {
            @include swap_direction(margin, 11px 15px 0 15px);
            height: 60px;
            line-height: 64px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.grid--mpp__item {
  .product--preorder {
    &.product--not-shoppable {
      .product__inventory-status {
        li {
          &.preorder-noshop {
            @include swap_direction(padding, 0);
            font-size: 17px;
          }
        }
      }
    }
  }
}

.preorder {
  &-bold {
    font-weight: bold;
  }
  &-highlight {
    color: $color--red;
  }
}

.favorites-page,
.past-purchases {
  .product__footer {
    .preorder_message {
      display: none;
    }
  }
}
