.product-foundation-vto {
  &__overlay-button {
    background-position: 20px;
  }
  &__btn {
    @include swap_direction(padding, 6px 9px 24px 34px);
  }
  &__guide-title,
  &__overlay-title,
  &__all-shades,
  &__guide,
  &__overlay-title,
  .current-shade span,
  .product-price {
    font-family: $roboto-condensed-bold-font;
  }
  &__overlay-copy,
  &__guide-copy,
  &__container ul.shade-list-vto li.vto-filter .vto-filter-text {
    font-family: $roboto-condensed-regular-font;
  }
  &__mobile-ctrl .container {
    .product-name {
      font-family: $roboto-condensed-bold-font;
    }
  }
}

.grid--mpp {
  .product {
    &__detail {
      .cta-vto {
        display: none;
      }
    }
  }
}
