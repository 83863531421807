// Remove the text-transform: uppercase; style on the global nav and footer
// elements
.site-header li.site-navigation__item,
.site-header li.site-navigation__more,
footer.site-footer {
  text-transform: none;
  a {
    text-transform: none;
  }
}

// Allow for longer copy in the custom palette page submenu
@include breakpoint($submenu-breakpoint) {
  .cppage-nav .submenu__header {
    .i18n-el-e-GR & {
      width: 40%;
    }
    .i18n-en-e-GR & {
      width: 30%;
    }
  }
}

.site-header {
  li.site-navigation__item,
  li.site-navigation__more {
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 0 10px);
    }
  }
}

.product-full__sku-extras--has-share {
  .product-full__sku-extras-share {
    width: 35%;
    @include breakpoint($bp--xxsmall-up) {
      width: 40%;
    }
    @include breakpoint($bp--xsmall-up) {
      width: 50%;
    }
  }
  .product-full__sku-extras-wishlist {
    width: 65%;
    @include breakpoint($bp--xxsmall-up) {
      width: 60%;
    }
    @include breakpoint($bp--xsmall-up) {
      width: 50%;
    }
  }
}

.spp-product-layout__content {
  .block-headline {
    font-family: $roboto-condensed-bold-font;
    @include breakpoint($bp--xsmall-down) {
      font-size: 34px;
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        &.pr-rd-main-header-with-filters {
          .pr-rd-review-header-contents {
            .pr-rd-search-container {
              left: 0;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(max-width $bp--medium-up) {
  .product--teaser {
    &.product--collection-dual {
      &.product--not-shoppable {
        + .carousel-controls {
          background: none;
          height: 85px;
        }
        .product {
          &__footer {
            .notify-status {
              a {
                &.notify_me {
                  margin: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.language {
  display: none;
}

.device-pc {
  .hotspot-product {
    &__card {
      .product-brief-v2 {
        .product-brief {
          &__cta {
            .product-add-to-bag {
              font-size: 12px;
              max-width: 60%;
            }
          }
        }
        .product-inventory-status {
          &__temp-oos-text {
            .notify-status {
              max-width: 60%;
              text-align: center;
              position: relative;
              .notify_me {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.device-mobile {
  .site-container {
    .field-content {
      .hotspot-product {
        div {
          overflow: unset;
          .product-brief-v2 {
            .product-inventory-status {
              &__temp-oos-text {
                .notify-status {
                  .notify_me {
                    text-align: center;
                  }
                }
              }
            }
            .product-brief {
              &__cta {
                .product-add-to-bag {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

div.LPMcontainer[role='button'] {
  pointer-events: none;
  .LPMimage {
    display: none;
  }
}

.looks-destination-page-formatter {
  .looks-destination-overlay {
    &__products-item {
      .product-brief-v2 {
        .product-brief {
          &__cta {
            .product-add-to-bag,
            .product-brief-cta {
              width: auto;
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}

.ins-preview-wrapper {
  height: auto;
}

.site-container {
  .product--teaser {
    .product {
      &__footer {
        .product {
          &__add-to-bag {
            width: auto;
            border-radius: 50px;
            padding: 0 20px;
            line-height: 3;
            height: 45px;
          }
        }
      }
    }
  }
}
