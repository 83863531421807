.email-customer-service-form {
  #questions {
    .product-tips {
      .picker {
        &:nth-child(3n + 0) {
          display: none;
        }
      }
    }
  }
}

.search-filter__trigger_icon--right {
  @include breakpoint($bp--xsmall) {
    position: static;
  }
  @include breakpoint($bp--xsmall-up $bp--small-up) {
    bottom: 21px;
    position: absolute;
  }
}

.culture-landing {
  .culture-landing__item {
    // Allow for 6 lines of header and content on 1024x768/ipads
    .culture-landing__teaser-title {
      width: 100%;
    }
    .culture-landing__teaser-wrap {
      height: 270px;
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .email_input {
      input {
        width: 100%;
      }
    }
    .align-r {
      .input-btn {
        width: 85px;
      }
    }
  }
}

#photo-upload-query {
  .profile-pic-upload__form-wrapper {
    .msg-instructions {
      width: 100%;
      .btn-instructions {
        width: 100%;
        #choose-file-btn {
          margin-right: 24px;
        }
        #file-chosen-state {
          margin-left: 0px;
        }
      }
      .btn-submit {
        width: 100%;
        float: left;
      }
    }
  }
}

@include breakpoint($bp--small-up) {
  .product--teaser {
    min-height: 515px;
  }
}

@include breakpoint($bp--medium-up) {
  .product--teaser {
    min-height: 480px;
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        display: block !important;
        li.product__inv-status-item {
          margin: 0px;
          padding: 0px;
          .notify-status {
            margin: 0px 14px 0 14px;
            a.notify_me {
              margin: 0px;
            }
          }
          .temp-out-of-stock__text {
            bottom: auto;
          }
        }
      }
    }
  }
}

.mobile-collection-products-carousel.slick-slider {
  ul.slick-dots {
    left: auto;
    right: auto;
    width: 100%;
  }
}

// Adjust SPP shade picker button to allow for Greek and the wider roboto font.
.view-all-shades--mobile {
  // Force a line break before the count
  #sku_count::before {
    content: '\a';
    white-space: pre;
  }
  // Adjust styling to allow for two lines of text
  line-height: 1;
  padding-top: 13px;
}

.site-container {
  .page--spp__product {
    .product--full {
      .product__rating.product__rating--header {
        top: 4px;
        text-align: right;
      }
    }
  }
  .store-locator {
    .form--search--advanced {
      .form--search {
        .form--inline {
          .form-type-countryfield {
            width: 40%;
            float: left;
            margin-right: 10px;
            .country_container {
              width: 100%;
              height: 61px;
            }
          }
          .form-type-textfield {
            width: 55%;
            float: left;
            .form-text search {
              width: 100%;
            }
          }
        }
      }
    }
  }
  @include breakpoint($bp--medium-down) {
    overflow: visible;
  }
}

.page-products-franchises {
  .grid--mpp {
    @include breakpoint($bp--medium) {
      margin-top: 0px;
    }
    @include breakpoint($bp--small-down) {
      margin-top: 10px !important;
    }
  }
}

// Increase the button size to allow longer copy
.artistry-video-player__products {
  .video-player__product-slide {
    .product--teaser--tiny {
      .product__add-to-bag,
      .product__link-to-spp {
        height: auto;
        line-height: normal;
        padding: 5px 0px 5px 0px;
      }
    }
  }
}

html {
  .site-container {
    .field-content {
      .custom-grid div {
        overflow: hidden;
      }
    }
  }
}

.product__detail {
  .product-full__review-snippet {
    position: relative;
    right: 0px;
    top: 0px;
    margin-bottom: 16px;
  }
}

@include breakpoint($bp--xxsmall-down) {
  .looks-destination-overlay {
    $self: &;
    &__products-container {
      #{$self} {
        &__products-item {
          .product-brief-v2 {
            padding-left: 0;
            .product-brief {
              &__cta {
                .product-add-to-bag {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}
