.pac-container {
  &.pac-logo {
    z-index: 9999;
  }
}

.account-page__content {
  .past-purchases.account-page__panel {
    .orders-list {
      &__table {
        a.order-detail-item-link {
          float: left;
        }
      }
    }
  }
}

.account-order-history {
  .past-purchases {
    .accordion-content.past-purchases__item {
      padding: 1em;
      .product-header {
        h6.quantity {
          margin-left: 28%;
          width: 19.66667%;
        }
        h6.rating {
          margin-left: 47%;
          width: 14.11111%;
        }
        h6.price {
          margin-left: 62%;
          margin-right: 0;
          float: left;
        }
      }
      .product--teaser {
        .product__qty {
          margin-left: 30%;
          width: 14.66667%;
        }
        .product__rate {
          margin-left: 42%;
          width: 15.11111%;
        }
        .product__price {
          margin-left: 62%;
          width: 16.11111%;
        }
      }
    }
  }
}

.loyalty-page__content,
.password-sent-page,
.password-request-page {
  padding: 0 10px;
}

.site-container {
  .past-purchases {
    .grid--mpp {
      .product__footer {
        .notify-status {
          a.notify_me {
            position: relative;
            padding: 0 20px;
            height: 60px;
            line-height: 64px;
            width: 100%;
            font-size: 1.5rem;
            margin: 30px 0 0 0;
          }
        }
        p.product-item__out-of-stock {
          text-align: center;
        }
      }
    }
    .orders-list {
      .orders-list__table {
        th {
          font-size: 1rem;
        }
      }
    }
  }
}

.site-header__menu {
  .header-search {
    .header-search__field {
      padding: 15px 5px;
    }
    .header-search__close {
      right: 3px;
    }
  }
}

.account-loyalty {
  .mac-select__account-panel__content {
    &-title p {
      font-family: $roboto-condensed-regular-font;
    }
  }
}

@if $password_strengthen {
  .profile-password-update {
    &__rules {
      li {
        width: 50%;
      }
    }
  }
}
