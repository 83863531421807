.enhanced-delivery-page {
  .checkout {
    &__content {
      #review-panel {
        .installopts {
          border-bottom: 1px solid $color--mercury;
          padding: 0 17px 7px;
          .select2-container {
            .select2-choice {
              height: 54px;
              line-height: 4.34;
              text-decoration: none;
              .select2-chosen {
                margin: 0 16px;
              }
              .select2-arrow {
                b {
                  &::before {
                    bottom: 20px;
                    opacity: 0.6;
                    transform: rotate(0);
                  }
                }
              }
            }
          }
          select.field {
            height: 54px;
            text-decoration: none;
          }
          &::before {
            #{$rdirection}: 28px;
            border: 5px solid transparent;
            border-top-color: $color--black;
            content: '';
            pointer-events: none;
            position: absolute;
            top: 26px;
          }
        }
      }
    }
    &__sidebar {
      &.right {
        #offer-code-panel {
          #offer_code {
            .form--offer_code_text {
              font-size: 10px;
              letter-spacing: 0;
            }
          }
        }
        #shopping-bag-wrapper-panel {
          border-bottom: 0;
        }
      }
    }
    .corporate-invoice {
      .corporate_options {
        input[type='text'] {
          font-size: 9px;
          letter-spacing: -0.3px;
        }
      }
    }
    #shipping-panel {
      .new-address-container {
        .country {
          .select2-container {
            display: none !important;
          }
          .field {
            display: none;
          }
        }
        .country-id {
          label {
            font-size: 12px;
          }
        }
        .default-shipping {
          margin-top: -16px;
        }
      }
      &.panel {
        .billing-address-form-fields {
          .form-item {
            &.company-name {
              margin-bottom: 0;
            }
            &.city {
              .field {
                display: block !important;
              }
            }
          }
        }
        .edit-shipping-address {
          .address {
            .field-container--grid {
              .form-item {
                .field {
                  &:focus + label {
                    &::before {
                      display: none;
                    }
                  }
                  &.adaptive-label-mode {
                    & + label {
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
                &.country-id {
                  .field {
                    display: none;
                  }
                }
                &.delivery_instructions {
                  .field {
                    & + label {
                      &::before {
                        margin-top: 48px;
                      }
                    }
                  }
                }
                .valid_marker,
                .invalid_marker {
                  display: none;
                }
                .valid_marker {
                  + .field {
                    border-color: $color--gray--lighter;
                    & + label {
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
                .invalid_marker {
                  + .field {
                    border-color: $color--gray--lighter;
                    & + label {
                      &::before {
                        color: $color--gray--lighter;
                      }
                    }
                    &:focus + label {
                      &::before {
                        display: none;
                      }
                    }
                    &:required {
                      &:invalid {
                        border-color: $color--gray--lighter;
                        & + label {
                          &::before {
                            color: $color--gray--lighter;
                          }
                        }
                      }
                      &:empty,
                      &:not(:checked) {
                        border-color: $color--gray--lighter;
                        & + label {
                          &::before {
                            color: $color--gray--lighter;
                          }
                        }
                      }
                      &.error,
                      &.checkedEmpty,
                      &.touched {
                        border-color: $color--gray--lighter;
                        & + label {
                          &::before {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &:not([class*='active-panel-signin']) {
      .info-link {
        &::before {
          bottom: 2px;
        }
      }
    }
  }
  &.active-panel-review {
    .review-panel {
      &.panel {
        #checkout_complete {
          .checkout__title {
            margin-top: 0;
          }
          .payment-type {
            .picker-radio {
              &:last-child {
                .picker-label {
                  .image_section {
                    width: 45%;
                    @include breakpoint($width-xlarge) {
                      width: 30%;
                    }
                  }
                }
              }
              #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mybank_label {
                .image_section {
                  margin-top: 3px;
                }
              }
              #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_cardlink_label {
                .image_section {
                  margin-top: 0;
                }
              }
            }
          }
          .payment-description {
            margin: 14px 16px;
            padding: 10px 17px;
            &-PAYMENT_OPTION_COD {
              border: 0;
              padding: 0;
            }
          }
          .picker-checkbox {
            border-top: 0;
          }
        }
      }
    }
  }
  &.checkout {
    #shipping-panel.panel {
      .new-address-container {
        margin-bottom: 24px;
        .bill-to-shipping {
          margin: 0;
          padding-top: 5px;
        }
      }
      .corporate-invoice {
        margin-bottom: 50px;
      }
      .address-list-added ~ .corporate-invoice {
        margin-top: 10px;
      }
    }
    &.active-panel-shipping {
      .saved-billing-address {
        #billing-panel-edit {
          top: 72px;
          @include breakpoint($width-xlarge) {
            top: 69px;
          }
        }
      }
    }
  }
}
