.enhanced_cart_page {
  .viewcart {
    .checkout {
      &__content {
        #viewcart-panel {
          .cart-item {
            &__discount-percentage {
              display: block;
              padding-top: 3px;
            }
          }
          .cart-items {
            .price-qty-total-column {
              .price {
                .cart-item {
                  &__unit-price {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .checkout__sidebar {
      .form--offer_code_text {
        &::placeholder {
          font-size: 8px;
        }
      }
    }
  }
}
